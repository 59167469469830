<template>
  <v-container
    id="sellers-edit"
    fluid
    tag="section"
  >
    <v-card>
      <v-overlay
        :value="loading"
        absolute
      >
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>

      <v-card-title class="text-center justify-center py-6">
        <h1 class="font-weight-bold display-3 basil--text">
          {{ seller.first_name }}
        </h1>
      </v-card-title>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-container fluid>
          <v-row>
            <v-col
              cols="12"
              md="5"
              sm="12"
              xs="12"
            >
              <v-text-field
                v-model="seller.first_name"
                :label="$t('views.sellers.forms.labels.first_name')"
                :rules="[rules.required]"
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              md="5"
              sm="12"
              xs="12"
            >
              <v-text-field
                v-model="seller.last_name"
                :label="$t('views.sellers.forms.labels.last_name')"
                :rules="[rules.required]"
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
              sm="6"
              xs="12"
            >
              <v-switch
                v-model="seller.enabled"
                :label="$t('views.sellers.forms.labels.enabled')"
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              sm="12"
              xs="12"
            >
              <v-text-field
                v-model="seller.email"
                :label="$t('views.sellers.forms.labels.email')"
                :rules="[rules.required]"
                prepend-inner-icon="mdi-email-outline"
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
              sm="12"
              xs="12"
            >
              <v-text-field
                v-model="seller.phone"
                :label="$t('views.sellers.forms.labels.phone')"
                prepend-inner-icon="mdi-phone-outline"
                outlined
                dense
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          @click="save"
        >
          {{ $t('forms.buttons.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <Snackbar />
  </v-container>
</template>

<script>

import EventBus from '@/plugins/event-bus'
import Snackbar from '@/components/core/Snackbar'

export default {
  name: 'SellersEdit',
  components: { Snackbar },
  data() {
    return {

      valid: false,
      loading:false,
      seller: {},
      rules: {
        required: value => !!value || this.$i18n.t('forms.fields.required'),
      },

    }
  },
  beforeMount() {
    this.load()
  },
  methods: {
    load() {
      if (this.$route.params.id) {
        this.loading = true
        this.$axios.get('sellers/' + this.$route.params.id)
          .then(response => {
            this.loading = false
            this.seller = response.data
          })
      }
    },
    save() {
      this.$refs.form.validate()
      if (!this.valid) return false

      this.loading = true
      this.$axios.put('sellers/' + this.$route.params.id, this.seller)
        .then(response => {
          this.loading = false
          this.seller = response.data
          this.snackbar(
            'green',
            this.$i18n.t('views.sellers.forms.messages.save_succeed', { sellerName : this.seller.first_name })
          )
        })
        .catch(error => {
          let error_message =  error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.loading = false
          this.snackbar(
            'error',
            this.$i18n.t('views.sellers.forms.messages.save_failed', { error : error_message })
          )
        })
    },
    snackbar(color, message) {
      EventBus.$emit('SNACKBAR', { color : color, message: message })
    },
  },
}
</script>

<style scoped>
</style>
